<template>
  <module-container
    :hide-filters="hideFilters"
    :show-toolbar="!hideFilters"
    :title="$t(sLabel)"
    dense
    hide-new-button
    route-name="invoices.received"
    route-name-create="invoices.create"
  >
    <template #route>
      <router-view :key="$route.fullPath" />
    </template>

    <template #filters>
      <invoice-filters :filter-key="sFilterKey" signed />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import InvoiceFilters from "@/modules/invoices/components/InvoiceFilters.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { InvoiceFilters },
})
export default class InvoicesReceived extends Vue {
  get hideFilters() {
    const arRouteNameList = [
      "invoices.received.update",
      "invoices.received.view",
    ];
    return this.$route.name
      ? arRouteNameList.includes(this.$route.name)
      : false;
  }

  get sLabel() {
    return `${this.sFilterKey.replace("_", ".")}.invoices`;
  }

  get sFilterKey() {
    switch (this.$route.name) {
      case "invoices.received.list":
        return "received";
      case "invoices.received.unprocessed":
        return "received_unprocessed";

      default:
        return "received";
    }
  }
}
</script>
